import { useSelector, useState } from "custom_exports/all_hooks";
import {
	Box,
	Button,
	Stack,
	Typography,
	Modal,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
	MenuItem,
} from "custom_exports/material_ui_items";
import { getCustomerItemsAsList } from "services/redux/reducers/slice";
import K from "common/keywords";

function CustomerSelectionListModal(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	// ---- destructuring ---- //
	const { showModal, setShowModal, handleOnClick: rootHandleOnClick } = props;

	// ---- assignments ---- //
	const [selectedCustomerId, setSelectedCustomerId] = useState("");
	const customersAsList = useSelector(getCustomerItemsAsList);

	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //
	const handleOnCustomerSelectionChange = (event) => {
		const {
			target: { value },
		} = event;

		setSelectedCustomerId(value);
	};

	const handleOnClick = (id, data) => {
		if (id === K.placeOrder) {
			setShowModal(false);
			rootHandleOnClick(K.placeOrder, { placedByUserId: selectedCustomerId });
		} else if (id === K.hideCustomerSelectionList) {
			setShowModal(false);
		}
	};

	//--------------------------------- contents ----------------------------------//
	const content = (
		<Modal open={showModal}>
			<Box
				sx={{
					position: "absolute",
					top: "30%",
					left: "50%",
					transform: "translate(-50%, -30%)",
					width: 400,
					bgcolor: "white",
					p: 4,
					pb: 2,
					borderRadius: 3,
				}}
			>
				<FormControl sx={{ width: "100%" }}>
					<InputLabel>select a customer</InputLabel>
					<Select
						value={selectedCustomerId}
						input={<OutlinedInput label="select a customer" />}
						onChange={handleOnCustomerSelectionChange}
					>
						{customersAsList.map((customer) => (
							<MenuItem key={customer.id} value={customer.id}>
								{customer.email}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Stack direction="row" justifyContent="flex-end" mt={4}>
					<Button onClick={() => handleOnClick(K.hideCustomerSelectionList)}>
						cancel
					</Button>
					<Button
						disabled={selectedCustomerId ? false : true}
						onClick={() => handleOnClick(K.placeOrder)}
					>
						ok
					</Button>
				</Stack>
			</Box>
		</Modal>
	);

	return content;
}

export default CustomerSelectionListModal;
