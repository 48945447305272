// Load Common App Data
import {
	useEffect,
	useDispatch,
	useSelector,
	useRCustomersMutation,
	useRProductsMutation,
	useROrdersMutation,
} from "custom_exports/all_hooks";
import { Outlet } from "react-router-dom";
import {
	customerActions,
	getUser,
	getCustomerItemsAsList,
	getProductItemsAsList,
	productActions,
	orderActions,
	getOrderItemsAsList,
} from "services/redux/reducers/slice";
import { callApi } from "common/api_handlers/api_call_manager";
import K from "common/keywords";
import C from "common/constants";

function LoadAppData(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	// ---- destructuring ---- //
	const { loadAll } = props;
	const { setCustomer } = customerActions;
	const { setProduct } = productActions;
	const { setOrder } = orderActions;

	// ---- assignments ---- //
	const dispatch = useDispatch();
	const [RCustomersApiCaller, RCustomersApiResult] = useRCustomersMutation();
	const [RProductsApiCaller, RProductsApiResult] = useRProductsMutation();
	const [ROrdersApiCaller, ROrdersApiResult] = useROrdersMutation();
	const user = useSelector(getUser);
	const customersAsList = useSelector(getCustomerItemsAsList);
	const productsAsList = useSelector(getProductItemsAsList);
	const ordersAsList = useSelector(getOrderItemsAsList);

	//--------------------------------- functions ---------------------------------//
	// ---- api managers ---- //
	const onApiResponse = ({ apiCallerId, ...response }) => {
		console.log(response);
		const { data } = response;
		if (apiCallerId === K.RCustomers) {
			dispatch(setCustomer(data.customers.items));
		} else if (apiCallerId === K.RProducts) {
			dispatch(setProduct(data.products.items));
		} else if (apiCallerId === K.ROrders) {
			dispatch(setOrder(data.orders.items));
		} else {
			console.log("onApiResponse is not defined for" + apiCallerId);
		}
	};

	const onApiError = ({ apiCallerId, ...error }) => {
		console.log(error);
	};

	const apiActions = { onApiResponse, onApiError };

	const callRCustomersApi = (apiInputParams, apiCallerId) => {
		callApi({
			apiCallerId: apiCallerId ?? K.RCustomers,
			apiCaller: RCustomersApiCaller,
			apiInputParams,
			apiActions,
		});
	};

	const callRProductsApi = (apiInputParams, apiCallerId) => {
		callApi({
			apiCallerId: apiCallerId ?? K.RProducts,
			apiCaller: RProductsApiCaller,
			apiInputParams,
			apiActions,
		});
	};

	const callROrdersApi = (apiInputParams, apiCallerId) => {
		callApi({
			apiCallerId: apiCallerId ?? K.ROrders,
			apiCaller: ROrdersApiCaller,
			apiInputParams,
			apiActions,
		});
	};

	useEffect(() => {
		if (
			user?.role &&
			user.role !== C.user_roles.customer &&
			customersAsList.length <= 0 &&
			loadAll
		) {
			callRCustomersApi();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (productsAsList.length <= 0 && loadAll) {
			callRProductsApi();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (ordersAsList.length <= 0 && loadAll) {
			callROrdersApi();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//--------------------------------- contents ----------------------------------//
	const content = <Outlet />;

	return content;
}

export default LoadAppData;
